import React, { useEffect, useState } from "react"
import { mergeStyles } from '@uifabric/merge-styles'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Navigate, useParams } from "react-router"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { useLocation } from "react-router"

const card = mergeStyles({
    // background: '#fff',
    marginBottom: 24,
    // boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.05)',
    // borderRadius: '6px',
})

const project = mergeStyles({
    // background: '#fff',
    marginTop: 12,
    marginBottom: 24,
    // boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.05)',
    // borderRadius: '6px',
})

const project_card = mergeStyles({
    background: '#fff',
    marginTop: 12,
    marginBottom: 24,
    cursor: 'pointer',
    border: '1px solid #0000',
    'selectors': {
        ':hover': {
            border: '1px solid #9999',
            // background: 'rgba(250, 255, 255)',
        }
    },
    transition: 'border 0.1s'
    // boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.05)',
    // borderRadius: '6px',
})

const card_inner = mergeStyles({
    padding: 16,
})

const page = mergeStyles({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: 24,
    display: 'block',
    width: 700,
    boxSizing: 'border-box',
    marginBottom: 48,
    'selectors': {
        'a': {
            color: '#446',
            textDecoration: 'none',
            fontSize: 18,
            selectors: {
                ':hover': {
                    textDecoration: 'underline',
                }
            }
        },
        'img': {
            width: '100%',
            display: 'block',
        }
    }
})

const hero = mergeStyles({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
})

const photo = mergeStyles({
    minWidth: 200,
    height: 200,
    background: '#aaa',
    border: '2px solid #333',
    borderRadius: 100,
    boxSizing: 'border-box',
    overflow: 'hidden',
    'selectors': {
        'img': {
            width: '100%',
            display: 'block',
        }
    }
})

const description = mergeStyles({
    'selectors': {
        'p': {
            marginBottom: 8,
        }
    }
})

const name = mergeStyles({
    fontSize: 36,
    marginBottom: 8,
    borderBottom: '1px dashed #333',
})

const project_title = mergeStyles({
    fontSize: 24,
    borderBottom: '1px dashed #333',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'end',
})

const project_lang = mergeStyles({
    // fontSize: 24,
    color: '#444'
})

const project_subtitle = mergeStyles({
    fontSize: 20,
})

const project_image = mergeStyles({
    width: '100%',
    // height: 400,
    // background: '#aaa',
    marginTop: 8,
    padding: 0,
    'selectors': {
        'img': {
            width: '100%',
            height: 'auto',
            display: 'block',
        }
    }
})

const project_h2 = mergeStyles({
    fontSize: 24,
    borderBottom: '1px dashed #333',
    marginBottom: 16,
    marginTop: 16,
})

const project_summary = mergeStyles({
    selectors: {
        'p': {
            marginBottom: 12,
        }
    }
})

const contact = mergeStyles({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    marginTop: 16,
})

interface Section {
    name: string
    description: string[]
}

interface ProjectInfo {
    name: string
    filename: string
    subtitle: string
    lang: string
    image?: string
    sections: Section[]
}

class ProjectCache {
    projects: Map<string, ProjectInfo>

    constructor() {
        this.projects = new Map<string, ProjectInfo>()
    }

    // retrieve by filename
    async get(name: string) {
        if (this.projects.has(name)) {
            return this.projects.get(name)
        } else {
            let response = await fetch(`/projects/${name}.json`)
            if (response.ok) {
                let data = await response.json()
                data.filename = name
                this.projects.set(name, data)
                return this.projects.get(name)
            } else {
                return null
            }
        }
    }

    async getAll(names: string[]) {
        let output: ProjectInfo[] = []
        for (let name of names) {
            let data = await this.get(name)
            if (data) {
                output.push(data)
            }
        }
        return output
    }
}

const cache = new ProjectCache()

export const App = () => {
    const location = useLocation()

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames={{
                enter: mergeStyles({
                    opacity: 0,
                    zIndex: 1,
                }),
                enterActive: mergeStyles({
                    opacity: 1,
                    transition: 'opacity 200ms'
                }),
                exit: mergeStyles({
                    opacity: 1,
                }),
                exitActive: mergeStyles({
                    opacity: 0,
                    transition: 'opacity 200ms'
                })
            }} timeout={200}>
                <Routes location={location}>
                    <Route path="/" element={<Home />} />
                    <Route path="/p/:name" element={<ProjectPage />} />
                    <Route path="*" element={<Navigate to='/' />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}

const Home = () => {
    const navigate = useNavigate()

    const [projects, setProjects] = useState<ProjectInfo[]>([])

    const project_names = [
        'chess',
        'web_game_engine',
        'hyperkey',
        'email_server',
        'web_server',
        'imperial_defense'
    ]

    useEffect(() => {
        cache.getAll(project_names).then(ps => {
            setProjects(ps)
        })
    }, [])
    
    return (
        <div className={page}>
            <div className={card}>
                <div className={card_inner}>
                    <div className={hero}>
                        <div className={photo}>
                            <img src="/img/profile.jpg" />
                        </div>
                        <div className={description}>
                            <div className={name}>Chris Luttio</div>
                            <div>
                                <p>I'm a software developer who has experience in Javascript, C#, C++, and other languages.</p>
                                <p>I worked at Spectralux Avionics for 3 years, writing different tools supporting configurations for their embedded devices.</p>
                                <p>I'm open for contract, employment and other services.</p>
                                <p>Below is a collection of various projects I've made, feel free to examine them as you wish.</p>
                            </div>

                            <div className={contact}>
                                <div><a href="mailto:chris.luttio@gmail.com">Email</a></div>
                                <div><a href="https://www.linkedin.com/in/chris-luttio/">Linkedin</a></div>
                                <div><a href="https://github.com/proconsular">Github</a></div>
                                <div><a href="/files/resume.pdf">Resume</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {projects.map((info, index) => {
                return <ProjectCard key={index} info={info} onSelect={() => {
                    navigate(`/p/${info.filename}`)
                }} />
            })}
        </div>
    )
}

const ProjectPage = () => {
    const { name } = useParams()
    const navigate = useNavigate()

    const [project, setProject] = useState<ProjectInfo>()

    useEffect(() => {
        cache.get(name).then(data => {
            if (data) {
                setProject(data)
            } else {
                navigate('/')
            }
        })
    }, [name])

    return (
        <div className={page}>
            <Link to='/'>Back</Link>
            {project && <Project info={project} />}
        </div>
    )
}

const Project = ({info} : {info: ProjectInfo}) => {
    return (
        <div className={project}>
            <div className={card_inner}>
                <div className={project_title}>
                    <div>{info.name}</div>
                    <div className={project_lang}>{info.lang}</div>
                </div>
                <div className={project_subtitle}>{info.subtitle}</div>
                <div>
                    {info.image && (
                        <div className={project_image}>
                            <img src={`/img/${info.image}`} />
                        </div>
                    )}
                    <div>
                        {info.sections.map((section, index) => {
                            return <Section key={index} section={section} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProjectCard = ({info, onSelect} : {info: ProjectInfo, onSelect: Function}) => {
    return (
        <div className={project_card}  onClick={() => {
            onSelect()
        }}>
            <div className={card_inner}>
                <div className={project_title}>
                    <div>{info.name}</div>
                    <div className={project_lang}>{info.lang}</div>
                </div>
                <div className={project_subtitle}>{info.subtitle}</div>
            </div>
        </div>
    )
}

const Section = ({section} : {section: Section}) => (
    <>
        <div className={project_h2}>{section.name}</div>
        <div className={project_summary}>
            {section.description.map((para, index) => {
                return <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
            })}
        </div>
    </>
)