// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n* {\n    margin: 0;\n    padding: 0;\n}\n\nbody {\n    background: rgb(235, 235, 235);\n    /* background-color: white; */\n}\n\n.W8NeTms2OMSJNQ42fYI4 {\n    font-size: 14px !important;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["\n* {\n    margin: 0;\n    padding: 0;\n}\n\nbody {\n    background: rgb(235, 235, 235);\n    /* background-color: white; */\n}\n\n.section_link {\n    font-size: 14px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section_link": "W8NeTms2OMSJNQ42fYI4"
};
export default ___CSS_LOADER_EXPORT___;
